import { FhirService } from "../../services/FhirService";
import { I18N } from "aurelia-i18n";
import { observable, bindable, autoinject, TaskQueue } from "aurelia-framework";
import { DialogService } from "aurelia-dialog";
import { fhirEnums } from "../../classes/fhir-enums";
import ResourceType = fhirEnums.ResourceType;
import CarePlanStatus = fhirEnums.CarePlanStatus;
import { RuntimeInfo } from "../../classes/RuntimeInfo";
import HTTPVerb = fhirEnums.HTTPVerb;
import { ModalTodoListProcedureEdit } from "../modal-todo-list-procedure-edit";
import EventStatus = fhirEnums.EventStatus;
import { ModalTodoListBulkProceduresEdit } from "../modal-todo-list-bulk-procedures-edit";
import { ModalTodoListProcedureRequestAdd } from "../modal-todo-list-procedure-request-add";
import RequestStatus = fhirEnums.RequestStatus;
import RequestIntent = fhirEnums.RequestIntent;
import { UserService } from "../../services/UserService";
// import {AureliaGridInstance, Column, FieldType, Filters, Formatter, Formatters, OnEventArgs} from "aurelia-slickgrid";
import { BindingSignaler } from "aurelia-templating-resources";
import { CarePlanService } from "../../services/CarePlanService";
import { ConfigService } from "../../services/ConfigService";
import * as environment from "../../../../config/environment.json";
import { PermissionService } from "resources/services/PermissionService";
import { ModalOrm } from "../modal-orm";
import { translations } from "resources/classes/translations";
import { NitTools } from "resources/classes/NursitTools";
import * as Fhir from "resources/classes/FhirModules/Fhir";

const moment = require("moment");

@autoinject
export class PatientCareplan {
    @bindable patient;

    dialogService;
    taskQueue;
    fhirService;
    i18n: I18N;
    userService;
    bindingSignaler;
    carePlanService: CarePlanService;
    permissionService: PermissionService;

    dpOptions = {
        useCurrent: true,
        format: 'ddd, DD.MM.YYYY',
        locale: RuntimeInfo.Language,
        showTodayButton: true,
        showClear: false,
        showClose: true,
        widgetPositioning: {
            horizontal: 'right',
            vertical: 'auto'
        },
        focusOnShow: false,
        ignoreReadonly: true
    };

    config;

    codeSystem;
    carePlan;
    carePlanProcedureRequests = [];
    requestGroups = [];

    isLoading = true;

    dateElement;
    @observable dateValue;
    dateObject;
    ranges = [];
    range = null;
    timeslots = [];

    hoveredTimeslot = -1;
    hoveredRequestGroup = -1;
    hoveredRequest = -1;

    activeView = 'list';

    //    aureliaGrid: AureliaGridInstance;

    gridContainer: HTMLElement;

    gridOptions;

    columnDefinitions;

    dataset = [];

    autoUpdateSignal;

    isToday = true;

    iframeUrl = '';

    iframeContainer;

    iframeOrigin;

    iframeListener;

    iframeUrlView = '';
    iframeContainerView;
    iframeOriginView;
    iframeListenerView;

    private readonly _resize;

    restrictDateTimeout;

    isPrescriptionEnabled = false;

    allowNoCarePlanAdhocs = false;

    isDiagnosticEnabled = false;

    isNewVirtualCarePlan = false;

    refreshTimeout;

    get isOffline() {
        return this.patient && this.patient.isOffline;
    }

    get hasCarePlan() {
        return Boolean(this.carePlan);
    }

    constructor(dialogService: DialogService, taskQueue: TaskQueue, fhirService: FhirService, i18n: I18N, userService: UserService, bindingSignaler: BindingSignaler, carePlanService: CarePlanService, permissionService: PermissionService) {
        this.dialogService = dialogService;
        this.taskQueue = taskQueue;
        this.fhirService = fhirService;
        this.i18n = i18n;
        this.userService = userService;
        this.bindingSignaler = bindingSignaler;
        this.carePlanService = carePlanService;
        this.permissionService = permissionService;

        this._resize = this.resize.bind(this);

        this.iframeListener = (event) => {
            if (event.origin === this.iframeOrigin) {
                const data = event.data.data;

                switch (event.data.name) {
                    case 'ready': {
                        this.getGridData();
                        break;
                    }
                    case 'edit': {
                        this.editProcedure(this.requestGroups[data.requestGroupIndex], this.requestGroups[data.requestGroupIndex].requests[data.requestIndex], this.requestGroups[data.requestGroupIndex].requests[data.requestIndex].procedures[data.procedureIndex]);
                        break;
                    }
                }
            }
        };

        this.iframeListenerView = (event) => {
            if (event.origin === this.iframeOriginView) {
                const data = event.data.data;
                switch (event.data.name) {
                    case 'height': {
                        if (this.iframeContainerView) {
                            this.iframeContainerView.style.height = `${data.height}px`;
                        }
                        break;
                    }
                    case 'editItem': {
                        this.dialogService.open({
                            viewModel: ModalOrm,
                            model: {
                                patient: this.patient,
                                type: 'interventions',
                                group: 'interventions',
                                serviceRequestReference: data.serviceRequest,
                                procedurePlannedTime: data.procedurePlannedTime
                            }
                        }).whenClosed((result) => {
                            if (!result.wasCancelled) {
                                if (this.iframeContainerView) {
                                    this.iframeContainerView.contentWindow.postMessage({
                                        name: 'refresh',
                                    }, this.iframeUrlView);
                                }
                            }
                        });
                    }
                }
            }
        };
    }

    attached() {
        document.body.classList.add("no-toolbar-window");

        this.dateObject = new Date();

        this.isDiagnosticEnabled = ConfigService.cfg.features?.orm?.interventionsUrl

        clearTimeout(this.restrictDateTimeout);
        this.restrictDateTimeout = setTimeout(() => {
            this.restrictDate();
        }, 1000);

        if (this.ranges.length === 0) {
            console.error('Missing Pflegeplan Timeslots in setup.json!');
            this.isLoading = false;
            return;
        }

        this.range = this.ranges.find(timeslot => timeslot.default) || this.ranges[0];

        this.buildTimeslots();

        window.addEventListener('resize', this._resize);

        this.autoUpdateSignal = setInterval(() => this.bindingSignaler.signal('is-in-timeslot'), 250);

        window.addEventListener("message", this.iframeListener);
        window.addEventListener("message", this.iframeListenerView);
    }

    detached() {
        clearTimeout(this.restrictDateTimeout);

        document.body.classList.remove("no-toolbar-window");

        window.removeEventListener('resize', this._resize);

        clearInterval(this.autoUpdateSignal);

        window.removeEventListener("message", this.iframeListener);
        window.removeEventListener("message", this.iframeListenerView);
    }

    resize() {
    }

    async setupIframeView() {
        if (!this.config?.settings?.iframeUrlView) {
            console.warn('No iframeUrlView configured in setup.json for Pflegeplan view.');
            return;
        }

        const loginData = sessionStorage.getItem(environment.sessionName);

        await this.userService.forceRefreshToken();

        this.buildTimeslots();

        const query:any = {
            login: loginData,
            server: FhirService.Endpoint,
            origin: window.location.origin,
            encounterId: this.patient.encounterId,
            patientId: this.patient.id,
            practitionerId: '',
            practitionerName: '',
            timeslots: JSON.stringify(this.timeslots.map((timeslot) => {
                return {
                    ...timeslot,
                    from: timeslot.from.toJSON(),
                    to: timeslot.to.toJSON()
                };
            }))
        };

        if (this.permissionService.isRolesEnabled) {
            query.roleId = this.permissionService.activeUserRole?.id;
        }

        if (this.userService.practitioner) {
            query.practitionerId = this.userService.practitioner.id;
            query.practitionerName = this.userService.fullNameOrUsername;
        }

        this.iframeUrlView = `${this.config?.settings?.iframeUrlView}?` + Object.keys(query).map((key) => {
            return `${key}=${encodeURIComponent(query[key])}`;
        }).join('&');

        this.iframeOriginView = this.iframeUrlView ? this.iframeUrlView.match(/^https?\:\/\/([^\/?#]+)/i)[0] : '';
    }

    iframeViewUpdateTimeslots() {
        if (!this.iframeUrlView) {
            return;
        }

        if (this.iframeContainerView) {
            this.iframeContainerView.contentWindow.postMessage({
                name: 'timeslots',
                data: {
                    timeslots: JSON.stringify(this.timeslots.map((timeslot) => {
                        return {
                            ...timeslot,
                            from: timeslot.from.toJSON(),
                            to: timeslot.to.toJSON()
                        };
                    }))
                }
            }, this.iframeUrlView);
        }
    }

    async patientChanged() {
        const planningConfig = ConfigService.GetFormSettings(ConfigService.FormNames.Planning);
        this.config = ConfigService.GetFormSettings(ConfigService.FormNames.CareProcess);

        this.allowNoCarePlanAdhocs = planningConfig && planningConfig.settings && planningConfig.settings.allowNoCarePlanAdhocs;
        this.isPrescriptionEnabled = planningConfig && planningConfig.settings && planningConfig.settings.allowPrescription;
        this.isNewVirtualCarePlan = planningConfig && planningConfig.settings && planningConfig.settings.newVirtualCarePlan;
        this.ranges = this.config && this.config.settings && this.config.settings.timeslots && this.config.settings.timeslots.length > 0 ? JSON.parse(JSON.stringify(this.config.settings.timeslots)) : [];

        clearTimeout(this.restrictDateTimeout);
        this.restrictDateTimeout = setTimeout(() => {
            this.restrictDate();
        }, 1000);

        if (this.ranges.length === 0) {
            this.isLoading = false;
            return;
        }

        this.isLoading = true;

        if (!this.permissionService.canAlert(PermissionService.FEATURES.CAREIT.CAREPLAN.VIEW)) {
            this.isLoading = false;
            return;
        }

        this.setupIframeView();

        this.codeSystem = await this.carePlanService.getGMTCodeSystem(this.patient);
        this.carePlan = await this.loadCarePlan();

        await this.loadProcedureBundles();
        this.sortAndProcessData();

        this.isLoading = false;

        this.startRefresh()

        if (this.config && this.config.settings && this.config.settings.iframeUrl) {
            const query = {
                login: sessionStorage.getItem(environment.sessionName),
                server: FhirService.Endpoint,
                encounterId: this.patient.encounterId,
                patientId: this.patient.id,
                practitionerId: '',
                practitionerName: '',
                origin: window.location.origin
            };

            if (this.userService.practitioner) {
                query.practitionerId = this.userService.practitioner.id;
                query.practitionerName = this.userService.fullNameOrUsername;
            }

            this.iframeUrl = `${this.config && this.config.settings && this.config.settings.iframeUrl}?` + Object.keys(query).map((key) => {
                return `${key}=${encodeURIComponent(query[key])}`;
            }).join('&');
            this.iframeOrigin = this.iframeUrl ? this.iframeUrl.match(/^https?\:\/\/([^\/?#]+)/i)[0] : '';
        }
    }

    async refresh(doNotTimeout = false) {
        clearTimeout(this.refreshTimeout);
        this.isLoading = true;

        await this.loadProcedureBundles();
        this.sortAndProcessData();
        this.getGridData();

        if (this.iframeContainerView) {
            this.iframeContainerView.contentWindow.postMessage({
                name: 'refresh',
            }, this.iframeUrlView);
        }

        this.isLoading = false;

        if (!doNotTimeout) {
            this.startRefresh()
        }
    }

    startRefresh() {
        clearTimeout(this.refreshTimeout);
        this.refreshTimeout = setTimeout(() => {
            this.refresh();
        }, 300000);
    }

    restrictDate() {
        if (!this.dateElement || !this.patient || !this.patient.encounter) {
            return false;
        }

        if (this.patient.encounter.period && this.patient.encounter.period.start) {
            this.dateElement.methods.minDate(moment(this.patient.encounter.period.start).toDate());
        } else {
            this.dateElement.methods.minDate(false);
        }

        if (this.patient.encounter.period && this.patient.encounter.period.end) {
            const periodEnd = moment(this.patient.encounter.period.end).toDate();

            if (moment(periodEnd).isBefore(moment(this.dateObject))) {
                this.dateObject = periodEnd;
            }

            this.dateElement.methods.maxDate(periodEnd);
        } else {
            this.dateElement.methods.maxDate(false);
        }

        return true;
    }

    async dateValueChanged() {
        if (this.isLoading || this.ranges.length === 0) {
            return;
        }

        this.isLoading = true;
        this.range = this.ranges.find(timeslot => timeslot.default) || this.ranges[0];
        this.isToday = Math.abs(moment(this.dateObject).startOf('day').diff(moment().startOf('day'), 'hours')) < 24;

        if (this.patient.encounter.period && this.patient.encounter.period.end && moment().isAfter(moment(this.patient.encounter.period.end))) {
            this.isToday = true;
        }

        this.buildTimeslots();
        await this.loadProcedureBundles();
        this.iframeViewUpdateTimeslots();
        this.sortAndProcessData();
        this.getGridData();
        this.isLoading = false;
    }

    async goToday() {
        if (this.isToday) {
            return;
        }

        this.dateObject = moment().toDate();
    }

    rangeChanged() {
        if (this.isLoading) {
            return;
        }

        this.buildTimeslots();
        this.iframeViewUpdateTimeslots();
        this.sortAndProcessData();
        this.getGridData();
    }

    loadCarePlan() {
        let context = `${ResourceType.encounter}/${this.patient.encounterId}`;

        return this.fhirService.fetch(`${ResourceType.carePlan}?${FhirService.FhirVersion > 3 ? 'encounter' : 'context'}=${context}&status=${CarePlanStatus.active}`).then(result => result[0]);
    }

    async loadCarePlanProcedureRequests() {
        let basedOn = `CarePlan/${this.carePlan.id}`;

        const result = await this.fhirService.fetch(`${(FhirService.FhirVersion > 3 ? ResourceType.serviceRequest : ResourceType.procedureRequest)}?based-on=${basedOn}&_revinclude=Procedure:based-on&status=active`);
        const serviceRequests = result.filter((resource) => resource.resourceType === (FhirService.FhirVersion > 3 ? ResourceType.serviceRequest : ResourceType.procedureRequest));
        const procedures = result.filter((resource) => resource.resourceType === ResourceType.procedure);

        return serviceRequests.map((serviceRequest) => {
            const procedureRequest = serviceRequest;
            const procedureRequestProcedures = procedures.filter((procedure) => {
                return procedure.basedOn.find((bo) => bo.reference === `${FhirService.FhirVersion > 3 ? ResourceType.serviceRequest : ResourceType.procedureRequest}/${procedureRequest.id}`);
            });

            return {
                procedureRequest,
                procedures: procedureRequestProcedures
            };
        });
    }

    async loadCarePlanProcedureRequestsOld() {
        let basedOn = `CarePlan/${this.carePlan.id}`;

        return this.fhirService.fetch(`${(FhirService.FhirVersion > 3 ? ResourceType.serviceRequest : ResourceType.procedureRequest)}?based-on=${basedOn}&status=active`);
    }

    newRequestGroup(rootData) {
        return {
            code: rootData.code,
            display: rootData.display,
            requests: [],
            eligibleProcedureRequests: 0
        };
    }

    newRequest(codeSystem) {
        return {
            codeSystem: codeSystem,
            procedureRequests: [],
            firstRelevantProcedureRequest: null,
            procedures: [],
            canAddProcedures: false,
            isInDateTime: false,
            totals: {
                current: 0,
                total: 0,
                minutes: 0
            }
        };
    }

    newProcedureRequest(procedureRequest, isActive, duration) {
        const procedureRequestTypeCategory = procedureRequest.category && procedureRequest.category.find((category) => category.coding[0].system === 'http://nursit-institute.com/fhir/StructureDefinition/procedure-request-type');
        const procedureRequestPrescriptionCategory = procedureRequest.category && procedureRequest.category.find((category) => category.coding[0].system === 'http://nursiti.com/CodeSystem/presc-needed');

        return {
            resource: procedureRequest,
            isActive: isActive,
            isControlled: procedureRequestTypeCategory ? procedureRequestTypeCategory.coding[0].code === 'controlled' : false,
            isPrescription: Boolean(procedureRequestPrescriptionCategory),
            qualification: procedureRequest.performerType && procedureRequest.performerType.coding[0].system === 'http://nursiti.com/CodeSystem/qualification' ? procedureRequest.performerType.coding[0].code : '',
            duration: duration
        };
    }

    newProcedure(procedure, procedureRequest) {
        return {
            resource: procedure,
            procedureRequest: procedureRequest
        };
    }

    async createServiceRequestForProcedure(procedure) {
        const dateFrom = moment(procedure.performedPeriod.start).startOf('day');
        const dateTo = moment(procedure.performedPeriod.start).endOf('day');

        const codeData = CarePlanService.findCode(procedure.code.coding[0].code, this.codeSystem);

        if (!codeData) {
            console.warn(`[patient-careplan] Unrecognized code ${procedure.code.coding[0].code} for ServiceRequest/ProcedureRequest in GMT Code System.`);
            return null;
        }

        const parsedCodeSystem = CarePlanService.parseCodeSystem(codeData);
        const procedureRequest = {
            resourceType: (FhirService.FhirVersion > 3 ? ResourceType.serviceRequest : ResourceType.procedureRequest),
            asNeededBoolean: true,
            authoredOn: new Date().toJSON(),
            subject: { reference: `${ResourceType.patient}/${this.patient.id}` },
            [FhirService.FhirVersion > 3 ? 'encounter' : 'context']: { reference: `${ResourceType.encounter}/${this.patient.encounterId}` },
            status: RequestStatus.active,
            intent: RequestIntent.instanceOrder,
            code: procedure.code,
            occurrenceTiming: {
                event: [dateFrom.toJSON(), dateTo.toJSON()],
                code: {
                    coding: [{
                        system: 'http://nursit-institute.com/fhir/StructureDefinition/gmt-timing',
                        code: 'as-needed',
                        display: 'As Needed'
                    }]
                },
                repeat: {
                    duration: parsedCodeSystem.properties.defaultDuration
                }
            }
        };

        const procedureRequestData = await this.fhirService.create(procedureRequest);
        procedure.basedOn = [{ reference: `${FhirService.FhirVersion > 3 ? ResourceType.serviceRequest : ResourceType.procedureRequest}/${procedureRequestData.id}` }];

        await this.fhirService.update(procedure);

        return procedureRequestData;
    }

    async loadInstanceOrderProcedureRequests(dateFrom, dateTo) {
        const resources = await this.fhirService.fetch(`${ResourceType.procedure}?${FhirService.FhirVersion > 3 ? 'encounter' : 'context'}=Encounter/${this.patient.encounterId}&date=ge${dateFrom.toJSON()}&date=le${dateTo.toJSON()}&_include=Procedure:based-on:${FhirService.FhirVersion > 3 ? ResourceType.serviceRequest : ResourceType.procedureRequest}`);
        const procedures = resources.filter((resource) => resource.resourceType === ResourceType.procedure);
        const procedureRequestsAll = resources.filter((resource) => resource.resourceType === (FhirService.FhirVersion > 3 ? ResourceType.serviceRequest : ResourceType.procedureRequest));
        const procedureRequests = [];

        const proceduresWithMissingBasedOn = procedures.filter((procedure) => {
            return !procedure.basedOn || procedure.basedOn.length === 0;
        });

        for (let i = 0; i < proceduresWithMissingBasedOn.length; i++) {
            const procedure = proceduresWithMissingBasedOn[i];

            if (procedure.code?.coding?.[0]?.system === "http://nursit-institute.com/fhir/StructureDefinition/carma-cs") {
                const procedureRequest = await this.createServiceRequestForProcedure(procedure);

                if (!procedureRequest) {
                    console.warn(`[patient-careplan] Cannot create ServiceRequest/ProcedureRequest for Procedure with id ${procedure.id} and system http://nursit-institute.com/fhir/StructureDefinition/carma-cs`);

                } else {
                    procedureRequestsAll.push(procedureRequest);
                }
            } else {
                // filter out the procedure from procedures
                const idx = procedures.findIndex((p) => p.id === procedure.id);

                if (idx > -1) {
                    procedures.splice(idx, 1);
                }
            }
        }

        // filter duplicates
        procedureRequestsAll.forEach((procedureRequest) => {
            const existingProcedureRequest = this.carePlanProcedureRequests.find((request) => request.procedureRequest.id === procedureRequest.id);

            if (!existingProcedureRequest) {
                procedureRequests.push({
                    procedureRequest,
                    procedures: procedures.filter((procedure) => {
                        return procedure.basedOn.find((bo) => bo.reference === `${FhirService.FhirVersion > 3 ? ResourceType.serviceRequest : ResourceType.procedureRequest}/${procedureRequest.id}`);
                    })
                })
            }
        });

        return procedureRequests;
    }

    async loadProcedureBundles() {
        if (!this.isNewVirtualCarePlan) {
            await this.loadProcedureBundlesOld();
            return
        }

        // get careplan service requests
        if (this.carePlan) {
            this.carePlanProcedureRequests = await this.loadCarePlanProcedureRequests();
        }

        // get the date range for which to show procedures
        const { lowestHour, highestHour } = this.getRangeRanges();
        const dateFrom = moment(this.dateObject).startOf('day').add(lowestHour, 'hours');
        const dateTo = moment(this.dateObject).startOf('day').add(highestHour, 'hours');

        // clear the request groups
        this.requestGroups = [];

        const procedureRequestBundles = []

        // add the careplan service requests
        this.carePlanProcedureRequests.forEach((procedureRequestBundle) => {
            procedureRequestBundles.push(procedureRequestBundle)
        });

        // add the instance order service requests (adhocs)
        (await this.loadInstanceOrderProcedureRequests(dateFrom, dateTo)).forEach((procedureRequest) => {
            procedureRequestBundles.push(procedureRequest)
        })
        

        for (const procedureRequestBundle of procedureRequestBundles) {
            const procedureRequest = procedureRequestBundle.procedureRequest;

            // ignore ORM (interventions) service requests
            if (procedureRequest.code.coding[0].system === 'http://nursit-institute.com/fhir/StructureDefinition/orm') {
                continue;
            }

            // prepare data
            const rootData = CarePlanService.findCode(procedureRequest.code.coding[0].code.split('.')[0], await this.carePlanService.getGMTCodeSystemByUri(procedureRequest.code.coding[0].system));
            const codeData = CarePlanService.findCode(procedureRequest.code.coding[0].code, await this.carePlanService.getGMTCodeSystemByUri(procedureRequest.code.coding[0].system));

            if (!rootData || !codeData) {
                console.warn(`[patient-careplan] Unrecognized code ${procedureRequest.code.coding[0].code} for ServiceRequest/ProcedureRequest ${procedureRequest.id} in GMT Code System. Skipping.`);
                continue;
            }

            const parsedCodeSystem = CarePlanService.parseCodeSystem(codeData);
            const parsedDuration = CarePlanService.parseCodeSystemDuration(procedureRequest.occurrenceTiming);

            // add request group if it doesnt exist
            let requestGroup = this.requestGroups.find((g) => g.code === rootData.code);

            if (!requestGroup) {
                requestGroup = this.newRequestGroup(rootData);

                this.requestGroups.push(requestGroup);
            }

            // add request if it doesnt exist
            let request = requestGroup.requests.find((b) => b.codeSystem.code === codeData.code);

            if (!request) {
                request = this.newRequest(parsedCodeSystem);

                requestGroup.requests.push(request);
            }

            // leftover from previous implementation when you could have active and inactive (from previous CarePlan) ServiceRequests showing at the same time (not relevant anymore with virtual procedure implementation)
            // now ServiceRequests are transferred over to the new CarePlan and are only shown in the new CarePlan
            let isActive = true;

            // add procedure request to list
            const procedureRequestItem = this.newProcedureRequest(procedureRequest, isActive, parsedDuration);

            request.procedureRequests.push(procedureRequestItem);

            // do virtual careplan stuff only for virtual careplan profiles and non-instanceOrder ServiceRequests
            if (this.carePlan?.meta.profile?.includes(CarePlanService.virtualCareplanProfile) && procedureRequest.intent !== RequestIntent.instanceOrder) {
                // calculate future timings both for legacy and new timing datatype (returns array of Moment.js dates)
                const procedureTimings = this.calculateFutureTimings(procedureRequest, parsedDuration, dateFrom, dateTo)

                procedureTimings.forEach((time) => {
                    // try to find existing procedure
                    let existingProcedure = procedureRequestBundle.procedures.find((procedure) => {
                        const extensionPlanned = procedure.extension?.find(ext => ext.url === 'http://hl7.org/fhir/StructureDefinition/planned-performed')?.valueDateTime

                        if (extensionPlanned) {
                            return moment(extensionPlanned).isSame(time)
                        }
            
                        return false
                    })

                    // create placeholder procedure if not found
                    if (!existingProcedure) {
                        existingProcedure = this.createProcedurePlaceholder(time, procedureRequest, parsedCodeSystem)
                    }

                    const proc = this.newProcedure(existingProcedure, procedureRequestItem);

                    // add procedure
                    request.procedures.push(proc);
                })
            }

            // add unmapped procedures (for instance order, legacy non-virtual procedures and additional procedures (added with the plus button))
            procedureRequestBundle.procedures.filter((procedure) => {
                return !request.procedures.find((proc) => proc.resource.id === procedure.id);
            }).filter((procedure) => {
                return moment(procedure.performedPeriod?.start).isSameOrAfter(dateFrom) && moment(procedure.performedPeriod?.start).isBefore(dateTo)
            }).forEach((procedure) => {
                const proc = this.newProcedure(procedure, procedureRequestItem);

                request.procedures.push(proc);
            });
        }
    }

    async loadProcedureBundlesOld() {
        if (this.carePlan) {
            this.carePlanProcedureRequests = await this.loadCarePlanProcedureRequestsOld();
        }

        const { lowestHour, highestHour } = this.getRangeRanges();

        const dateFrom = moment(this.dateObject).startOf('day').add(lowestHour, 'hours');
        const dateTo = moment(this.dateObject).startOf('day').add(highestHour, 'hours');

        const resources = await this.fhirService.fetch(`${ResourceType.procedure}?${FhirService.FhirVersion > 3 ? 'encounter' : 'context'}=Encounter/${this.patient.encounterId}&date=ge${dateFrom.toJSON()}&date=le${dateTo.toJSON()}&_include=Procedure:based-on:${FhirService.FhirVersion > 3 ? ResourceType.serviceRequest : ResourceType.procedureRequest}`);
        const procedures = resources.filter((resource) => resource.resourceType === ResourceType.procedure);
        const procedureRequestsAll = resources.filter((resource) => resource.resourceType === (FhirService.FhirVersion > 3 ? ResourceType.serviceRequest : ResourceType.procedureRequest));
        const procedureRequests = [];

        const proceduresWithMissingBasedOn = procedures.filter((procedure) => {
            return !procedure.basedOn || procedure.basedOn.length === 0;
        });

        for (let i = 0; i < proceduresWithMissingBasedOn.length; i++) {
            const procedure = proceduresWithMissingBasedOn[i];

            if (procedure.code?.coding?.[0]?.system === "http://nursit-institute.com/fhir/StructureDefinition/carma-cs") {
                const procedureRequest = await this.createServiceRequestForProcedure(procedure);

                if (!procedureRequest) {
                    console.warn(`[patient-careplan] Cannot create ServiceRequest/ProcedureRequest for Procedure with id ${procedure.id} and system http://nursit-institute.com/fhir/StructureDefinition/carma-cs`);

                } else {
                    procedureRequestsAll.push(procedureRequest);
                }
            } else {
                // filter out the procedure from procedures
                const idx = procedures.findIndex((p) => p.id === procedure.id);

                if (idx > -1) {
                    procedures.splice(idx, 1);
                }
            }
        }

        // filter duplicates
        procedureRequestsAll.forEach((procedureRequest) => {
            const existingProcedureRequest = procedureRequests.find((request) => request.id === procedureRequest.id);

            if (!existingProcedureRequest) {
                procedureRequests.push(procedureRequest);
            }
        });

        // add careplan procedure requests
        this.carePlanProcedureRequests.forEach((procedureRequest) => {
            const existingProcedureRequest = procedureRequests.find((request) => request.id === procedureRequest.id);

            if (!existingProcedureRequest) {
                procedureRequests.push(procedureRequest);
            }
        });

        this.requestGroups = [];

        if (ConfigService.Debug)
            console.debug('Procedures', procedures);

        for (const procedureRequest of procedureRequests) {
            let carePlanId;

            if (procedureRequest.basedOn) {
                procedureRequest.basedOn.forEach((bo) => {
                    const boSplit = bo.reference.split('/');

                    if (boSplit[0] === 'CarePlan') {
                        carePlanId = boSplit[1];
                    }
                });
            }

            if (procedureRequest.code.coding[0].system === 'http://nursit-institute.com/fhir/StructureDefinition/orm') {
                continue;
            }

            if (!procedureRequest.occurrenceTiming.event) {
                procedureRequest.occurrenceTiming.event = [dateTo];
            }

            const rootData = CarePlanService.findCode(procedureRequest.code.coding[0].code.split('.')[0], await this.carePlanService.getGMTCodeSystemByUri(procedureRequest.code.coding[0].system));
            const codeData = CarePlanService.findCode(procedureRequest.code.coding[0].code, await this.carePlanService.getGMTCodeSystemByUri(procedureRequest.code.coding[0].system));

            if (!rootData || !codeData) {
                console.warn(`[patient-careplan] Unrecognized code ${procedureRequest.code.coding[0].code} for ServiceRequest/ProcedureRequest ${procedureRequest.id} in GMT Code System. Skipping.`);
                continue;
            }

            const parsedCodeSystem = CarePlanService.parseCodeSystem(codeData);
            const parsedDuration = CarePlanService.parseCodeSystemDuration(procedureRequest.occurrenceTiming);
            let requestGroup = this.requestGroups.find((g) => g.code === rootData.code);

            if (!requestGroup) {
                requestGroup = this.newRequestGroup(rootData);

                this.requestGroups.push(requestGroup);
            }

            let request = requestGroup.requests.find((b) => b.codeSystem.code === codeData.code);

            if (!request) {
                request = this.newRequest(parsedCodeSystem);

                requestGroup.requests.push(request);
            }

            let isActive = false;

            if (!carePlanId) {
                if (procedureRequest.occurrenceTiming && procedureRequest.occurrenceTiming.event && procedureRequest.occurrenceTiming.event[1]) {
                    isActive = moment(procedureRequest.occurrenceTiming.event[1]).isAfter(moment());
                }
            } else {
                isActive = this.carePlan && this.carePlan.id === carePlanId;
            }

            const procedureRequestItem = this.newProcedureRequest(procedureRequest, isActive, parsedDuration);

            request.procedureRequests.push(procedureRequestItem);

            procedures.filter((procedure) => {
                const procedureRequestReference = procedure.basedOn.find((bo) => bo.reference.startsWith(FhirService.FhirVersion > 3 ? ResourceType.serviceRequest : ResourceType.procedureRequest));
                const [rType, procedureRequestId] = procedureRequestReference.reference.split('/');

                return procedureRequest.id === procedureRequestId;
            }).forEach((procedure) => {
                const proc = this.newProcedure(procedure, procedureRequestItem);

                request.procedures.push(proc);
            });
        }

        if (ConfigService.Debug)
            console.debug('Request groups', this.requestGroups);
    }

    createProcedurePlaceholder (time, procedureRequest, codeSystem, status: fhir4.Procedure['status'] = 'preparation'): fhir4.Procedure {
        const _time = moment(time)

        return {
          id: NitTools.Uid(),
          resourceType: 'Procedure',
          basedOn: [
            {
                reference: this.carePlan?.id ? `${ResourceType.carePlan}/${this.carePlan.id}` : undefined
            },
            {
              reference: `${procedureRequest.resourceType}/${procedureRequest.id}`
            }
          ],
          subject: { reference: `Patient/${this.patient.id}` },
          [FhirService.FhirVersion > 3 ? 'encounter' : 'context']: { reference: `Encounter/${this.patient.encounterId}` },
          status,
          note: [{ text: '' }],
          category: procedureRequest.category?.find((category) => category.coding[0].system === 'http://nursit-institute.com/fhir/StructureDefinition/procedure-request-type'),
          code: {
                coding: [{
                    system: procedureRequest.code.coding[0].system,
                    code: codeSystem.code
                }]
            },
          extension: [
            {
              url: 'http://hl7.org/fhir/StructureDefinition/planned-performed',
              valueDateTime: _time.toJSON()
            }
          ],
          performedPeriod: {
            start: _time.toJSON(),
            end: _time
              .clone()
              .add(
                procedureRequest.occurrenceTiming?.repeat?.duration,
                'minutes'
              )
              .toJSON()
          }
        }
      }

    calculateFutureTimings (serviceRequest, duration, dateFrom_, dateTo_, maxLimit = 10000, customStart = null) {
        if (serviceRequest.occurrenceTiming.code?.coding?.find((coding: any) => coding.system === 'http://nursit-institute.com/fhir/StructureDefinition/timing-datatype')) {
            return this.calculateFutureTimingsNew(serviceRequest, duration, dateFrom_, dateTo_, maxLimit, customStart)
        } else {
            return this.calculateFutureTimingsOld(serviceRequest, duration, dateFrom_, dateTo_)
        }
    }

    calculateFutureTimingsNew (serviceRequest, duration, dateFrom_, dateTo_, maxLimit = 10000, customStart = null) {
        if (!serviceRequest?.occurrenceTiming?.repeat) {
            return []
        }
    
        const weekDaysOptions = [
        {
            title: translations.translate('mon'),
            value: 'mon'
        },
        {
            title: translations.translate('tue'),
            value: 'tue'
        },
        {
            title: translations.translate('wed'),
            value: 'wed'
        },
        {
            title: translations.translate('thu'),
            value: 'thu'
        },
        {
            title: translations.translate('fri'),
            value: 'fri'
        },
        {
            title: translations.translate('sat'),
            value: 'sat'
        },
        {
            title: translations.translate('sun'),
            value: 'sun'
        }
        ]
        const monthWeek = serviceRequest.occurrenceTiming.repeat.extension?.find(ext => ext.url === 'http://hl7.org/fhir/StructureDefinition/timing-month-week')?.valueCodeableConcept?.coding?.map(coding => coding.code)
        const monthDay = serviceRequest.occurrenceTiming.repeat.extension?.find(ext => ext.url === 'http://hl7.org/fhir/StructureDefinition/timing-month-day')?.valueCodeableConcept?.coding?.map(coding => coding.code)
        const serviceRequestStart = moment(customStart || serviceRequest.occurrenceTiming.event[0])
        const serviceRequestEnd = serviceRequest.occurrenceTiming.event[1] && moment(serviceRequest.occurrenceTiming.event[1])
        const period = serviceRequest.occurrenceTiming.repeat.periodUnit
        const repeatEvery = serviceRequest.occurrenceTiming.repeat.period
        const timeOfDay = serviceRequest.occurrenceTiming.repeat.timeOfDay?.map((tod, idx) => {
        const [hours, minutes] = tod.split(':')
    
        return {
            time: {
            hours: parseInt(hours, 10),
            minutes: parseInt(minutes, 10)
            },
            day: serviceRequest.occurrenceTiming.repeat.dayOfWeek?.[idx] || null,
            date: monthDay ? new Date(2018, 0, parseInt(monthDay[idx], 10)) : null,
            weekNumber: monthWeek ? parseInt(monthWeek[idx], 10) : null
        }
        }) || []
    
        let monthType
    
        if (serviceRequest.occurrenceTiming.repeat?.extension?.find(ext => ext.url === 'http://hl7.org/fhir/StructureDefinition/timing-month-day')) {
        monthType = 'day_of_month'
        } else if (serviceRequest.occurrenceTiming.repeat?.extension?.find(ext => ext.url === 'http://hl7.org/fhir/StructureDefinition/timing-month-week')) {
        monthType = 'day_of_week'
        }
    
        const dateTo = !serviceRequestEnd || serviceRequestEnd.isAfter(dateTo_) ? dateTo_ : serviceRequestEnd
    
        const timings = []
        let numAllTimings = 0
    
        let date = moment(serviceRequest.occurrenceTiming.event[0])
        let i = 0
    
        if (!period) {
            if (serviceRequestStart.isSameOrAfter(dateFrom_) && serviceRequestStart.isSameOrBefore(dateTo_)) {
                return [serviceRequestStart]
            }
        }
    
        mainLoop: while (i < 10000) {
        if (!['min', 'h'].includes(period)) {
            for (let j = 0; j < timeOfDay.length; j++) {
            const tod = timeOfDay[j]
    
            if (period === 'd') {
                date.set({
                hour: tod.time.hours,
                minute: tod.time.minutes
                })
            } else if (period === 'wk') {
                const targetDay = weekDaysOptions.findIndex(wd => wd.value === tod.day)
                date.hour(tod.time.hours)
                date.minute(tod.time.minutes)
                date.isoWeekday(1).add(targetDay, 'days')
            } else if (period === 'mo') {
                if (monthType === 'day_of_week') {
                let weekCount = 0
                const maxDateInMonth = date.clone().endOf('month').date()
                let targetDate
    
                for (let d = 1; d <= maxDateInMonth; d++) {
                    const currentDate = date.clone().date(d)
                    if (currentDate.isoWeekday() === weekDaysOptions.findIndex(wd => wd.value === tod.day) + 1) {
                    weekCount++
                    if (tod.weekNumber >= 0 && weekCount === tod.weekNumber + 1) {
                        targetDate = currentDate
                        break
                    }
                    }
                }
    
                if (tod.weekNumber === -1 && !targetDate) {
                    for (let d = maxDateInMonth; d > 0; d--) {
                    const currentDate = date.clone().date(d)
                    if (currentDate.isoWeekday() === weekDaysOptions.findIndex(wd => wd.value === tod.day) + 1) {
                        targetDate = currentDate
                        break
                    }
                    }
                }
    
                if (!targetDate) {
                    continue
                }
    
                date = targetDate
                date.hour(tod.time.hours)
                date.minute(tod.time.minutes)
                } else if (monthType === 'day_of_month') {
                const maxDateInMonth = date.clone().endOf('month').date()
    
                for (let d = 0; d < maxDateInMonth; d++) {
                    const currentDate = date.clone().date(d)
    
                    if (currentDate.date() === tod.date.getDate()) {
                    date = currentDate
                    date.hour(tod.time.hours)
                    date.minute(tod.time.minutes)
                    break
                    }
                }
                }
            }
    
            if (dateTo && date.isAfter(moment(dateTo))) {
                break mainLoop
            } else if (date.isSameOrAfter(serviceRequestStart) && numAllTimings < maxLimit) {
                numAllTimings++
    
                if (date.isSameOrAfter(dateFrom_)) {
                timings.push(moment(date.toJSON()))
                }
            }
            }
        } else {
            if (dateTo && date.isAfter(moment(dateTo))) {
            break mainLoop
            } else if (date.isSameOrAfter(serviceRequestStart) && numAllTimings < maxLimit) {
            numAllTimings++
    
            if (date.isSameOrAfter(dateFrom_)) {
                timings.push(moment(date.toJSON()))
            }
            }
        }
    
        let _repeatEvery
    
        if (date.isBefore(serviceRequestStart)) {
            _repeatEvery = 1
        } else {
            _repeatEvery = repeatEvery
        }
    
        switch (period) {
            case 'min':
            date.minute(date.minute() + _repeatEvery)
            break
            case 'h':
            date.hour(date.hour() + _repeatEvery)
            break
            case 'd':
            date.day(date.day() + _repeatEvery)
            break
            case 'wk':
            date.week(date.week() + _repeatEvery)
            break
            case 'mo':
            date.date(1)
            date.month(date.month() + _repeatEvery)
            break
            default:
            return []
        }
    
        i++
        }
    
        return timings
    }

    calculateFutureTimingsOld (serviceRequest, duration, dateFrom_, dateTo_) {
        if (duration.type === 'interval') {
            if (duration.frequency > 0) {
                return this.generatePerPeriod(serviceRequest, duration, dateFrom_, dateTo_)
            } else {
                return this.generateEveryPeriod(serviceRequest, duration, dateFrom_, dateTo_)
            }
        }

        return []
    }

    generatePerPeriod(procedureRequest, duration, dateFrom_, dateTo_) {
        const { start: serviceRequestDateFrom, end: serviceRequestDateTo } = Fhir.Tools.GetTimingStartEndDate(procedureRequest.occurrenceTiming);
        const weekDays = [
            'sun',
            'mon',
            'tue',
            'wed',
            'thu',
            'fri',
            'sat'
        ];
        const timings = []

        if (moment(serviceRequestDateFrom).isSameOrAfter(dateTo_) || (serviceRequestDateTo && moment(serviceRequestDateTo).isSameOrAfter(dateFrom_))) {
            return []
        }

        if (procedureRequest.occurrenceTiming.repeat && procedureRequest.occurrenceTiming.repeat.timeOfDay) {
            procedureRequest.occurrenceTiming.repeat.timeOfDay.map((tod, idx) => {
                const [hour, minute] = tod.split(':');
                const time = moment(dateFrom_).startOf('day').add(hour, 'hours').add(minute, 'minutes');
                const weekDay = duration.periodUnit === 'wk' && procedureRequest.occurrenceTiming.repeat.dayOfWeek ? procedureRequest.occurrenceTiming.repeat.dayOfWeek[idx] : null;

                if (time.isSameOrAfter(dateFrom_) && time.isSameOrBefore(dateTo_) && time.isSameOrAfter(serviceRequestDateFrom) && (!serviceRequestDateTo || time.isSameOrBefore(moment(serviceRequestDateTo))) && (!weekDay || weekDays[time.day()] == weekDay)) {
                    timings.push(time);
                }
            });
        } else {
            const startDate = moment(dateFrom_)

            while(startDate.isSameOrBefore(dateTo_)) {
                const scale = CarePlanService.getScaleForPerPeriod(startDate, duration);

                if (!scale) {
                    return [];
                }

                for (let i = 0; i < scale.num; i++) {
                    if (scale.step.isSameOrAfter(dateFrom_) && scale.step.isSameOrBefore(dateTo_) && scale.step.isSameOrAfter(serviceRequestDateFrom) && (!serviceRequestDateTo || scale.step.isSameOrBefore(moment(serviceRequestDateTo)))) {
                        timings.push(moment(scale.step));
                    }

                    scale.step.add(scale.distance, 'minutes');
                }

                startDate.add(1, 'day');
            }
        }

        return timings;
    }

    generateEveryPeriod(procedureRequest, duration, dateFrom_, dateTo_) {
        const { start: serviceRequestDateFrom, end: serviceRequestDateTo } = Fhir.Tools.GetTimingStartEndDate(procedureRequest.occurrenceTiming);
        const scale = CarePlanService.getScaleForEveryPeriod(duration);
        const step = moment(serviceRequestDateFrom);
        const timings = [];

        while (step.isSameOrBefore(dateTo_) && (!serviceRequestDateTo || step.isSameOrBefore(moment(serviceRequestDateTo)))) {
            if (step.isSameOrAfter(dateFrom_)) {
                timings.push(moment(step));
            }

            step.add(scale, 'minutes');
        }

        return timings;
    }

    sortAndProcessData() {
        const { lowestHour, highestHour } = this.getRangeRanges();

        const startDate = moment(this.dateObject).startOf('day').add(lowestHour, 'hours');
        const endDate = moment(this.dateObject).startOf('day').add(highestHour, 'hours');

        this.requestGroups.sort((a, b) => {
            return a.code - b.code;
        });

        this.requestGroups.forEach((requestGroup) => {
            requestGroup.eligibleProcedureRequests = 0;

            requestGroup.requests.sort((a, b) => {
                const displayA = a.codeSystem.display.toUpperCase();
                const displayB = b.codeSystem.display.toUpperCase();

                if (displayA < displayB) {
                    return -1;
                }
                if (displayA > displayB) {
                    return 1;
                }

                return 0;
            });

            requestGroup.requests.forEach((request) => {
                let isInDateTime = false;
                let canAddProcedures = false;
                let firstRelevantProcedureRequest = null;
                const eligibleProcedureRequests = [];

                request.procedureRequests.sort((a, b) => {
                    const aStart = moment(a.resource.occurrenceTiming.event[0]);
                    const bStart = moment(b.resource.occurrenceTiming.event[0]);

                    if (aStart.isBefore(bStart)) return 1;
                    else if (bStart.isBefore(aStart)) return -1;

                    return 0;
                }).forEach((procedureRequest) => {
                    const event = procedureRequest.resource.occurrenceTiming.event;
                    const start = moment(event[0]);
                    const end = event[1] ? moment(event[1]) : endDate;

                    if (start.isBefore(endDate) && end.isAfter(startDate)) {
                        isInDateTime = true;

                        if (!canAddProcedures && procedureRequest.isActive && (procedureRequest.resource.intent === 'plan' || procedureRequest.resource.intent === 'instance-order')) {
                            canAddProcedures = true;
                        }

                        if (!firstRelevantProcedureRequest) {
                            firstRelevantProcedureRequest = procedureRequest;
                        }

                        eligibleProcedureRequests.push(procedureRequest);
                    }
                });

                request.procedures.sort((a, b) => {
                    const aStart = moment(a.resource.performedPeriod.start);
                    const bStart = moment(b.resource.performedPeriod.start);

                    if (aStart.isBefore(bStart)) return -1;
                    else if (bStart.isBefore(aStart)) return 1;

                    return 0;
                });

                if (isInDateTime) {
                    requestGroup.eligibleProcedureRequests++;
                }

                request.isInDateTime = isInDateTime;
                request.canAddProcedures = canAddProcedures;
                request.firstRelevantProcedureRequest = firstRelevantProcedureRequest;

                if (firstRelevantProcedureRequest) {
                    request.totals.total = this.getProcedureRequestTotal(eligibleProcedureRequests, request.procedures);
                    request.totals.minutes = this.calculateTotalProcedureTime(request.procedures);
                    request.totals.current = this.getAmountProceduresForDay(request.procedures, true);
                }
            });
        });
    }

    getRangeRanges() {
        let lowestHour = 0, highestHour = 24;

        this.ranges.forEach((range) => {
            range.slots.forEach((slot) => {
                if (lowestHour > slot.time[0]) {
                    lowestHour = slot.time[0];
                }

                if (highestHour < slot.time[1]) {
                    highestHour = slot.time[1];
                }
            });
        });

        return { lowestHour, highestHour };
    }

    getProcedureRequestTotal(procedureRequests, procedures) {
        const firstProcedureRequest = procedureRequests[0];

        if (firstProcedureRequest.resource.intent === 'plan') {
            switch (firstProcedureRequest.duration.type) {
                case 'interval': {
                    return procedureRequests.map((pr) => pr.resource).reduce((acc, procedureRequest) => {
                        return acc + CarePlanService.countProcedureIntervals(procedureRequest, this.timeslots[0].from, this.timeslots[this.timeslots.length - 1].to, moment(this.dateObject).startOf('day'), CarePlanService.parseCodeSystemDuration(procedureRequest.occurrenceTiming));
                    }, 0);
                }
                case 'as-needed': {
                    return -1;
                }
                case 'at-admission': {
                    return -2;
                }
                case 'at-release': {
                    return -3;
                }
                case 'everlasting': {
                    return -4;
                }
            }
        } else {
            const minTimeslot = this.timeslots[0].from;
            const maxTimeslot = this.timeslots[this.timeslots.length - 1].to;
            let numProcedures = 0;

            procedures.forEach((procedure) => {
                const procedureAt = moment(procedure.resource.performedPeriod.start);

                if (procedureAt.isSameOrAfter(minTimeslot) && procedureAt.isBefore(maxTimeslot)) {
                    numProcedures++;
                }
            });

            return numProcedures;
        }
    }

    buildTimeslots() {
        const date = moment(this.dateObject).startOf('day');
        const timeslots = [];

        this.range.slots.forEach((slot) => {
            timeslots.push({
                title: slot.title,
                subtitle: slot.subtitle,
                showDay: slot.showDay,
                from: moment(date).add(slot.time[0], 'hours'),
                to: moment(date).add(slot.time[1], 'hours')
            });
        });

        this.timeslots = timeslots;
    }

    isInTimeslot(timeslot, procedure) {
        if (this.isLoading) {
            return false;
        }

        const procedureAt = moment(procedure.resource.performedPeriod.start);

        return procedureAt.isSameOrAfter(timeslot.from) && procedureAt.isBefore(timeslot.to);
    }

    calculateTotalProcedureTime(procedures) {
        return Math.round(procedures.map(procedure => procedure.resource).filter((procedure) => procedure.status === 'completed' && (!procedure.hasOwnProperty('notDone') || procedure.notDone === false || procedure.notDone === undefined)).reduce((total, procedure) => {
            return total + moment.duration(moment(procedure.performedPeriod.end).diff(moment(procedure.performedPeriod.start))).asMinutes();
        }, 0));
    }

    getAmountProceduresForDay(procedures, onlyCompleted = false) {
        let amount = 0;

        for (let i = 0; i < procedures.length; i++) {
            const procedure = procedures[i].resource;

            if (moment(procedure.performedPeriod.start).isSameOrAfter(moment(this.dateObject).startOf('day')) && moment(procedure.performedPeriod.start).isBefore(moment(this.dateObject).endOf('day')) && (!onlyCompleted || onlyCompleted && procedure.status !== EventStatus.preparation && procedure.status !== EventStatus.enteredInError)) {
                amount++;
            }
        }

        return amount;
    }

    hoverRequest(procedureRequestGroup, procedureRequest) {
        this.hoveredRequestGroup = procedureRequestGroup;
        this.hoveredRequest = procedureRequest;
    }

    unhoverRequest() {
        this.hoveredRequestGroup = -1;
        this.hoveredRequest = -1;
    }

    async editProcedure(requestGroup, request, procedure) {
        if (this.isLoading || UserService.IsReadonly) {
            return;
        }

        await this.refresh(true);

        const currentRequestGroup = this.requestGroups.find((rg) => rg.code === requestGroup.code);
        const currentRequest = currentRequestGroup.requests.find((r) => r.codeSystem.code === request.codeSystem.code);
        const currentProcedureIndex = currentRequest.procedures.findIndex((p) => p.resource.id === procedure.resource.id || p.resource.extension?.find(ext => ext.url === 'http://hl7.org/fhir/StructureDefinition/planned-performed')?.valueDateTime === procedure.resource.extension?.find(ext => ext.url === 'http://hl7.org/fhir/StructureDefinition/planned-performed')?.valueDateTime);
        const currentProcedureRequest = currentRequest.procedures[currentProcedureIndex].procedureRequest;

        if (this.isOffline) return;

        this.dialogService.open({
            viewModel: ModalTodoListProcedureEdit,
            model: {
                request: currentRequest,
                procedureRequest: currentProcedureRequest,
                procedureIndex: currentProcedureIndex,
                carePlan: this.carePlan,
                patient: this.patient
            }
        }).whenClosed(async (result) => {
            if (!result.wasCancelled) {
                this.sortAndProcessData();
                this.getGridData();
            }

            this.startRefresh()
        });
    }

    async addProcedure(request) {
        if (this.isOffline || UserService.IsReadonly) return;

        if (!this.permissionService.canAlert(PermissionService.FEATURES.CAREIT.CAREPLAN.PROCEDURE.ADD, {
            GMT: (param) => param.values.some((v) => request.codeSystem.code.startsWith(v.code))
        })) {
            return;
        }

        let now = moment();
        let currentTimeIsInTimeslot = false;
        let dateTime;

        // find the current timeslot
        for (let i = 0; i < this.timeslots.length; i++) {
            const timeslot = this.timeslots[i];

            if (timeslot.from.isSameOrBefore(now) && timeslot.to.isAfter(now)) {
                currentTimeIsInTimeslot = true;
            }
        }

        // set the start of the new procedure to either now or to the found timeslow for now
        if (currentTimeIsInTimeslot) {
            dateTime = now;
        } else {
            dateTime = this.timeslots[this.timeslots.length - 1].from;
        }

        const firstProcedureRequest = request.procedureRequests[0];
        const periodStart = moment(dateTime);
        const periodEnd = moment(dateTime).add(firstProcedureRequest.resource.occurrenceTiming.repeat.duration, 'minutes');

        const procedureRequestTypeCategory = firstProcedureRequest.resource.category && firstProcedureRequest.resource.category.find((category) => category.coding[0].system === 'http://nursit-institute.com/fhir/StructureDefinition/procedure-request-type');

        const procedure: any = {
            resourceType: ResourceType.procedure,
            basedOn: [
                { reference: `${FhirService.FhirVersion > 3 ? ResourceType.serviceRequest : ResourceType.procedureRequest}/${firstProcedureRequest.resource.id}` }],
            subject: { reference: `${ResourceType.patient}/${this.patient.id}` },
            [FhirService.FhirVersion > 3 ? 'encounter' : 'context']: { reference: `${ResourceType.encounter}/${this.patient.encounterId}` },
            status: EventStatus.preparation,
            category: procedureRequestTypeCategory,
            code: {
                coding: [{
                    system: firstProcedureRequest.resource.code.coding[0].system,
                    code: request.codeSystem.code
                }]
            },
            performedPeriod: {
                start: periodStart.toDate().toJSON(),
                end: periodEnd.toDate().toJSON()
            }
        };

        if (this.carePlan?.id) {
            procedure.basedOn.unshift({ reference: `${ResourceType.carePlan}/${this.carePlan.id}` });
        }

        this.dialogService.open({
            viewModel: ModalTodoListProcedureEdit,
            model: {
                codeSystem: request.codeSystem,
                procedure: procedure,
                procedureRequest: firstProcedureRequest,
                createMode: true,
                carePlan: this.carePlan,
                patient: this.patient
            }
        }).whenClosed((result) => {
            if (!result.wasCancelled) {
                request.procedures.push(this.newProcedure(result.output, firstProcedureRequest));

                this.sortAndProcessData();
                this.getGridData();
            }
        });
    }

    async bulkEditProceduresCol(timeslot) {
        if (this.isOffline || this.isLoading || UserService.IsReadonly) return;
        const requests = [];
        const bulkItems = [];

        await this.refresh(true)

        for (let i = 0; i < this.requestGroups.length; i++) {
            const requestGroup = this.requestGroups[i];

            for (let j = 0; j < requestGroup.requests.length; j++) {
                const request = requestGroup.requests[j];

                for (let n = 0; n < request.procedures.length; n++) {
                    const procedure = request.procedures[n];

                    if (this.isInTimeslot(timeslot, procedure)) {
                        requests.push(request);
                        bulkItems.push({
                            procedure: procedure,
                            procedureIndex: n,
                            request: request
                        });
                    }
                }
            }
        }

        if (bulkItems.length === 0) {
            return;
        }

        this.dialogService.open({
            viewModel: ModalTodoListBulkProceduresEdit,
            model: {
                bulk: bulkItems,
                patient: this.patient
            }
        }).whenClosed((result) => {
            if (!result.wasCancelled) {
                requests.forEach((request) => {
                    request.totals.current = this.getAmountProceduresForDay(request.procedures, true);
                    request.totals.minutes = this.calculateTotalProcedureTime(request.procedures);

                    this.getGridData();
                });
            }

            this.startRefresh()
        });
    }

    async bulkEditProceduresRow(requestGroup, request) {
        if (this.isOffline || this.isLoading || UserService.IsReadonly) return;

        if (request.procedures.length === 0) {
            return;
        }

        await this.refresh(true)

        const currentRequestGroup = this.requestGroups.find((rg) => rg.code === requestGroup.code);
        const currentRequest = currentRequestGroup.requests.find((r) => r.codeSystem.code === request.codeSystem.code);

        this.dialogService.open({
            viewModel: ModalTodoListBulkProceduresEdit,
            model: {
                bulk: currentRequest.procedures.filter((procedure) => {
                    const minTimeslot = this.timeslots[0].from;
                    const maxTimeslot = this.timeslots[this.timeslots.length - 1].to;
                    const procedureDateTime = moment(procedure.resource.performedPeriod.start);

                    return procedureDateTime.isSameOrAfter(minTimeslot) && procedureDateTime.isBefore(maxTimeslot);
                }).map((procedure, index) => {
                    return {
                        procedure: procedure,
                        procedureIndex: index,
                        request: request
                    };
                }).sort((a, b) => {
                    const aStart = moment(a.procedure.resource.performedPeriod.start);
                    const bStart = moment(b.procedure.resource.performedPeriod.start);

                    if (aStart.isAfter(bStart)) return 1;
                    else if (bStart.isAfter(aStart)) return -1;

                    return 0;
                }),
                patient: this.patient
            }
        }).whenClosed((result) => {
            if (!result.wasCancelled) {
                request.totals.current = this.getAmountProceduresForDay(request.procedures, true);

                request.totals.minutes = this.calculateTotalProcedureTime(request.procedures);

                this.getGridData();
            }

            this.startRefresh()
        });
    }

    addProcedureRequest() {
        if (this.isOffline || (!this.allowNoCarePlanAdhocs && !this.hasCarePlan) || UserService.IsReadonly) return;

        if (!this.permissionService.canAlert(PermissionService.FEATURES.CAREIT.CAREPLAN.ADHOC)) {
            return;
        }

        this.dialogService.open({
            viewModel: ModalTodoListProcedureRequestAdd,
            model: {
                requestGroups: this.requestGroups,
                codeSystem: this.codeSystem
            }
        }).whenClosed(async (result) => {
            if (!result.wasCancelled && result.output.length > 0) {
                for (let i = 0; i < result.output.length; i++) {
                    const codeSystem = result.output[i];
                    const parsedCodeSystem = CarePlanService.parseCodeSystem(codeSystem);
                    const dateFrom = moment().startOf('day');
                    const dateTo = moment().endOf('day');
                    const procedureRequest: fhir3.ProcedureRequest | fhir4.ServiceRequest = {
                        resourceType: (FhirService.FhirVersion > 3 ? ResourceType.serviceRequest : ResourceType.procedureRequest),
                        asNeededBoolean: true,
                        authoredOn: new Date().toJSON(),
                        subject: { reference: `${ResourceType.patient}/${this.patient.id}` },
                        [FhirService.FhirVersion > 3 ? 'encounter' : 'context']: { reference: `${ResourceType.encounter}/${this.patient.encounterId}` },
                        status: RequestStatus.active,
                        intent: RequestIntent.instanceOrder,
                        code: {
                            coding: [{
                                system: CarePlanService.getGmtCodeSystemUri(this.patient),
                                code: parsedCodeSystem.code,
                                display: parsedCodeSystem.display
                            }]
                        },
                        occurrenceTiming: {
                            event: [dateFrom.toJSON(), dateTo.toJSON()],
                            code: {
                                coding: [{
                                    system: 'http://nursit-institute.com/fhir/StructureDefinition/gmt-timing',
                                    code: 'as-needed',
                                    display: 'As Needed'
                                }]
                            },
                            repeat: {
                                duration: parsedCodeSystem.properties.defaultDuration
                            }
                        }
                    };

                    this.isLoading = true;

                    if (this.userService.practitioner) {
                        procedureRequest.performer = { 
                            reference: `${ResourceType.practitioner}/${this.userService.practitioner.id}`,
                            display: this.userService.fullNameOrUsername
                         };
                    }

                    const rootData = CarePlanService.findCode(procedureRequest.code.coding[0].code.split('.')[0], this.codeSystem);
                    const codeData = CarePlanService.findCode(procedureRequest.code.coding[0].code, this.codeSystem);
                    const parsedDuration = CarePlanService.parseCodeSystemDuration(procedureRequest.occurrenceTiming);
                    let requestGroup = this.requestGroups.find((g) => g.code === rootData.code);

                    if (!requestGroup) {
                        requestGroup = this.newRequestGroup(rootData);

                        this.requestGroups.push(requestGroup);
                    }

                    let request = requestGroup.requests.find((b) => b.codeSystem.code === codeData.code);

                    if (!request) {
                        request = this.newRequest(parsedCodeSystem);

                        requestGroup.requests.push(request);
                    }

                    const procedureRequestItem = this.newProcedureRequest(await this.fhirService.create(procedureRequest), true, parsedDuration);

                    request.procedureRequests.unshift(procedureRequestItem);

                    const periodStart = moment();
                    const periodEnd = moment(periodStart).add(parsedCodeSystem.properties.defaultDuration, 'minutes');
                    const procedure = {
                        resourceType: 'Procedure',
                        basedOn: [
                            { reference: `${FhirService.FhirVersion > 3 ? ResourceType.serviceRequest : ResourceType.procedureRequest}/${procedureRequestItem.resource.id}` }],
                        subject: {
                            reference: `${ResourceType.patient}/${this.patient.id}`
                        },
                        [FhirService.FhirVersion > 3 ? 'encounter' : 'context']: {
                            reference: `${ResourceType.encounter}/${this.patient.encounterId}`
                        },
                        status: 'preparation',
                        code: {
                            coding: [{
                                system: CarePlanService.getGmtCodeSystemUri(this.patient),
                                code: parsedCodeSystem.code
                            }]
                        },
                        performedPeriod: {
                            start: periodStart.toJSON(),
                            end: periodEnd.toJSON()
                        }
                    };

                    if (this.carePlan?.id) {
                        procedure.basedOn.unshift({ reference: `${ResourceType.carePlan}/${this.carePlan.id}` });
                    }

                    request.procedures.push(this.newProcedure(await this.fhirService.create(procedure), procedureRequestItem));
                }

                this.sortAndProcessData();
                this.getGridData();
                this.isLoading = false;
            }
        });
    }

    addDiagnostic() {
        if (this.isOffline || !this.hasCarePlan || UserService.IsReadonly) return;

        if (!this.permissionService.canAlert(PermissionService.FEATURES.CAREIT.CAREPLAN.ORDER)) {
            return;
        }

        this.dialogService.open({
            viewModel: ModalOrm,
            model: {
                patient: this.patient,
                type: 'interventions',
                group: 'interventions',
            }
        }).whenClosed((result) => {
            if (!result.wasCancelled) {
                if (this.iframeContainerView) {
                    this.iframeContainerView.contentWindow.postMessage({
                        name: 'refresh',
                    }, this.iframeUrlView);
                }
            }
        });
    }

    switchView(newMode?: string) {
        if (!newMode) {
            if (this.activeView == 'list') {
                this.activeView = 'table';
            } else {
                this.activeView = 'list';
            }
        } else {
            this.activeView = newMode;
        }
    }

    /*    aureliaGridReady(aureliaGrid: AureliaGridInstance) {
            this.aureliaGrid = aureliaGrid;
        } */

    /* Define grid Options and Columns */
    defineGrid() {
        /*
          // noinspection JSUnusedLocalSymbols
          const customDateTimeFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any, grid: any) => {
              return moment(value).format(RuntimeInfo.DateTimeFormat);
          };

          // noinspection JSUnusedLocalSymbols
          const customStatusFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any, grid: any) => {
              return this.i18n.tr(value);
          };

          // noinspection JSUnusedGlobalSymbols
          this.columnDefinitions = [
              {
                  id: 'edit',
                  field: 'id',
                  name: '<a click.delegate="true"><i class="fa fa-pencil pointer edit-icon"></i></a>',
                  excludeFromHeaderMenu: true,
                  formatter: Formatters.editIcon,
                  minWidth: 30,
                  maxWidth: 30,
                  // use onCellClick OR grid.onClick.subscribe which you can see down below
                  onCellClick: (e: Event, args: OnEventArgs) => {
                      if (args.dataContext) {
                          this.editProcedure(args.dataContext.data.task, args.dataContext.data.idx);
                      }

                      this.aureliaGrid.gridService.highlightRow(args.row, 1500);
                      this.aureliaGrid.gridService.setSelectedRow(args.row);
                  }
              },
              {
                  id: 'time',
                  name: this.i18n.tr('time'),
                  field: 'time',
                  headerKey: 'time',
                  sortable: true,
                  minWidth: 50,
                  width: 50,
                  type: FieldType.date,
                  formatter: customDateTimeFormatter
              },
              {
                  id: 'category',
                  name: this.i18n.tr('category'),
                  field: 'category',
                  headerKey: 'category',
                  sortable: true,
                  filterable: true,
                  filter: {
                      model: Filters.compoundInputText
                  }
              },
              {
                  id: 'procedure',
                  name: this.i18n.tr('procedure'),
                  field: 'procedure',
                  headerKey: 'procedure',
                  sortable: true,
                  filterable: true,
                  filter: {
                      model: Filters.compoundInputText
                  }
              },
              {
                  id: 'duration',
                  name: this.i18n.tr('duration_mins'),
                  field: 'duration',
                  headerKey: 'duration_mins',
                  sortable: true,
                  minWidth: 48,
                  width: 48,
                  type: FieldType.number,
                  filterable: true,
                  filter: {
                      model: Filters.compoundInputNumber
                  }
              },
              {
                  id: 'status',
                  name: this.i18n.tr('status'),
                  field: 'status',
                  headerKey: 'status',
                  sortable: true,
                  minWidth: 50,
                  width: 50,
                  filterable: true,
                  formatter: customStatusFormatter,
                  filter: {
                      collection: [{value: 'planned', labelKey: 'planned'}, {
                          value: 'done',
                          labelKey: 'done'
                      }, {value: 'not_done', labelKey: 'not_done'}, {value: 'canceled', labelKey: 'canceled'}],
                      model: Filters.multipleSelect,
                  }
              },
              {
                  id: 'comment',
                  name: this.i18n.tr('comment'),
                  field: 'comment',
                  headerKey: 'comment',
                  sortable: true,
                  minWidth: 100,
                  filterable: true,
                  filter: {
                      model: Filters.compoundInputText
                  }
              },
              {
                  id: 'nursing_note',
                  name: this.i18n.tr('nursing_note'),
                  field: 'nursing_note',
                  headerKey: 'nursing_note',
                  sortable: true,
                  minWidth: 42,
                  width: 42,
                  formatter: Formatters.checkmark,
                  type: FieldType.boolean,
                  filterable: true,
                  filter: {
                      collection: [{value: '', label: ''}, {value: true, labelKey: 'yes'}, {
                          value: false,
                          labelKey: 'no'
                      }],
                      model: Filters.singleSelect,
                  }
              },
          ];

          this.gridOptions = {
              enableAutoResize: true,
              autoHeight: true,
              enableColumnPicker: true,
              enableCellNavigation: true,
              enableRowSelection: true,
              enableFiltering: true,
              enableTranslate: true,
              forceFitColumns: true,
              i18n: this.i18n,
              presets: {
                  sorters: [
                      {columnId: 'time', direction: 'ASC'},
                      {columnId: 'category', direction: 'ASC'},
                      {columnId: 'procedure', direction: 'ASC'}
                  ]
              }
          };

         */
    }

    getGridData() {
        if (this.iframeContainer) {
            try {
                this.iframeContainer.contentWindow.postMessage({
                    name: 'update',
                    data: {
                        requestGroups: this.requestGroups,
                        timeslots: JSON.stringify(this.timeslots)
                    }
                }, this.iframeUrl);
            } catch (e) {
            }
        }
        /*
        this.dataset = [];

        let id = 0;

        for (let i = 0; i < this.requestGroups.length; i++) {
            const requestGroup = this.requestGroups[i];

            for (let j = 0; j < requestGroup.requests.length; j++) {
                const request = requestGroup.requests[j];

                request.procedures.filter((procedure) => {
                    const minTimeslot = this.timeslots[0].from;
                    const maxTimeslot = this.timeslots[this.timeslots.length - 1].to;
                    const procedureDateTime = moment(procedure.resource.performedPeriod.start);

                    return procedureDateTime.isSameOrAfter(minTimeslot) && procedureDateTime.isBefore(maxTimeslot);
                }).forEach((procedure, idx) => {
                    let currentStatus, comment;

                    if (procedure.resource.status === EventStatus.preparation) {
                        currentStatus = 'planned';
                        comment = '';
                    } else if (procedure.resource.status === EventStatus.completed) {
                        if (!procedure.resource.notDone) {
                            currentStatus = 'done';
                            comment = procedure.resource.note && procedure.resource.note[0].text || '';
                        } else {
                            currentStatus = 'not_done';
                            comment = procedure.resource.notDoneReason.text ? procedure.resource.notDoneReason.text : this.i18n.tr('not_done_reason_' + procedure.resource.notDoneReason.coding.find((c) => c.system === RuntimeInfo.SystemHeader + '/procedure-not-done-reason').code);
                        }
                    } else if (procedure.status === EventStatus.aborted) {
                        currentStatus = 'canceled';
                        comment = procedure.resource.notDoneReason.text ? procedure.resource.notDoneReason.text : this.i18n.tr('not_done_reason_' + procedure.resource.notDoneReason.coding.find((c) => c.system === RuntimeInfo.SystemHeader + '/procedure-not-done-reason').code);
                    }

                    this.dataset.push({
                        id: id++,
                        time: moment(procedure.resource.performedPeriod.start).toDate(),
                        category: requestGroup.display,
                        procedure: request.codeSystem.display,
                        duration: moment.duration(moment(procedure.resource.performedPeriod.end).diff(moment(procedure.resource.performedPeriod.start))).asMinutes(),
                        status: currentStatus,
                        comment,
                        nursing_note: procedure.resource.hasOwnProperty('followUp'),
                        data: {
                            procedureRequestData: request,
                            procedure: procedure.resource,
                            procedureIndex: idx
                        }
                    });
                });
            }
        }

         */
    }

    onHeaderClicked(e, args) {
        // if (args && args.column && args.column.id === 'edit') {
        //     const bulkItems = [];
        //     const tasks = [];
        //
        //     this.aureliaGrid.dataView.getFilteredItems().forEach((dataset) => {
        //         tasks.push(dataset.data.task);
        //
        //         bulkItems.push({
        //             procedure: dataset.data.procedure,
        //             task: dataset.data.task,
        //             mapping: dataset.data.mapping
        //         })
        //     });
        //
        //     if (tasks.length === 0) {
        //         return;
        //     }
        //
        //     // noinspection JSIgnoredPromiseFromCall
        //     this.dialogService.open({
        //         viewModel: ModalTodoListBulkProceduresEdit,
        //         model: {
        //             bulk: bulkItems.sort((a, b) => {
        //                 if (a.mapping.title > b.mapping.title) return 1;
        //                 if (a.mapping.title < b.mapping.title) return -1;
        //                 if (a.task.text > b.task.text) return 1;
        //                 if (a.task.text < b.task.text) return -1;
        //
        //                 return 0;
        //             }),
        //             patient: this.patient
        //         }
        //     }).whenClosed((result) => {
        //         if (!result.wasCancelled) {
        //             tasks.forEach((task) => {
        //                 task.proceduresPerDay = this.getAmountProceduresForDay(task.procedures, true);
        //
        //                 this.calculateTotalProcedureTime(task);
        //
        //                 this.getGridData();
        //             });
        //         }
        //     });
        // }
    }
}
